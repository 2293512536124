// Render the total count of accepted/assigned/requested trucks from a set of jobs
import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { reduce } from 'lodash';

import { useAuthContext } from '@/lib/context/AuthContext';
import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

import { getCompanyTrucksInfo } from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

export default function TotalTrucksCount({
  jobs,
  isLoading,
}: {
  jobs: JobDoc[];
  isLoading: boolean;
}) {
  const [acceptedTrucksCount, setAcceptedTrucksCount] = useState(0);
  const [assignedTrucksCount, setAssignedTrucksCount] = useState(0);
  const [requestedTrucksCount, setRequestedTrucksCount] = useState(0);
  const { userCompanyId } = useAuthContext();
  const { selectedJob } = useEditSchedulerContext();

  useEffect(() => {
    const counts = reduce(
      jobs,
      (counts, jobDoc) => {
        const companyTrucksInfo = getCompanyTrucksInfo(jobDoc, userCompanyId);
        return {
          acceptedTrucksCount:
            counts.acceptedTrucksCount +
            (companyTrucksInfo?.trucksAcceptedCount || 0),
          assignedTrucksCount:
            counts.assignedTrucksCount +
            (companyTrucksInfo?.trucksAssignedCount || 0),
          requestedTrucksCount:
            counts.requestedTrucksCount +
            (companyTrucksInfo?.trucksRequestedCount || 0),
        };
      },
      {
        acceptedTrucksCount: 0,
        assignedTrucksCount: 0,
        requestedTrucksCount: 0,
      }
    );
    setAcceptedTrucksCount(counts.acceptedTrucksCount);
    setAssignedTrucksCount(counts.assignedTrucksCount);
    setRequestedTrucksCount(counts.requestedTrucksCount);
  }, [jobs]);

  const counts = [
    { value: acceptedTrucksCount, label: 'Accepted' },
    { value: assignedTrucksCount, label: 'Assigned' },
    { value: requestedTrucksCount, label: 'Requested' },
  ];
  return (
    <div
      className={clsx(
        'total-trucks-count grid w-[50%] grid-cols-[1fr_10px_1fr_10px_1fr] md:w-[12%]',
        !!selectedJob && 'invisible' // Hide total trucks count when a Job is selected
      )}
    >
      {counts.map(({ value, label }, idx) => (
        <React.Fragment key={label}>
          <div className="flex flex-col items-center">
            <div className="text-xl/4 font-extrabold tracking-widest">
              {isLoading ? (
                <div className="skeleton h-4 w-10 rounded-sm bg-base-200"></div>
              ) : (
                value
              )}
            </div>
            <div className="text-[0.66rem] tracking-tighter">{label}</div>
          </div>
          {idx < counts.length - 1 && (
            <div className="flex justify-center">{'/'}</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
