// Component to switch between the current day of dispatch schedule
import clsx from 'clsx';

import { useSchedulerContext } from '@/lib/context/SchedulerContext';

import { addDays, daysLabels, getIsSameDay } from '@/lib/helpers/dates';

export default function SchedulerDaySwitch() {
  const { schedulerCurrentDate, schedulerStartDate, setSchedulerCurrentDate } =
    useSchedulerContext();
  return (
    <div className="scheduler-day-switch tabs mb-[-2px] h-full grid-cols-7 justify-between lg:tabs-bordered md:flex md:h-auto lg:justify-start">
      {daysLabels.map((dayLabel, idx) => {
        const date = addDays(schedulerStartDate, idx);
        const isActive = getIsSameDay(schedulerCurrentDate, date);
        const dateTip = date.toDateString();
        const isToday = dateTip === new Date().toDateString();
        return (
          <div
            key={dayLabel}
            className={clsx(
              'tab h-full min-h-12 grow px-0 text-lg lg:px-10',
              isActive
                ? 'tab-active bg-base-150'
                : '!border-base-200 font-bold text-primary-dark'
            )}
            onClick={() => setSchedulerCurrentDate(date)}
          >
            <div
              className="tooltip tooltip-top"
              data-tip={`${dateTip}${isToday ? ' (Today)' : ''}`}
            >
              {dayLabel}
              {isToday && (
                <div className="today-indicator absolute left-4 top-3 text-xl font-bold">
                  {'.'}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
